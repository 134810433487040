import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../services/nav.service';
import { filter, map } from 'rxjs/operators';
//import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  activeRoute = null;
  langs = ['fr', 'de', 'es', 'en'];
  activeLang = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private navService: NavService,
    //private dateAdapter: DateAdapter<Date>
  ) {
    navService.setActiveNavItem(null);
  }

  ngOnInit() {
    // we subscribe to child route path to change auth content dynamically
    this.activeLang = this.translate.currentLang;
    this.activeRoute = this.route.firstChild.routeConfig.path;
    this.router.events.pipe(map(evt => evt instanceof NavigationEnd))
      .subscribe((event) => {
        this.activeRoute = this.route.firstChild.routeConfig.path;
      });
  }

  changeLang(l) {
    if (l === 'fr') {
      this.translate.use('fr');
      this.activeLang = 'fr';
      localStorage.setItem('lang', 'fr');
      return;
    }
    if (l === 'de') {
      this.translate.use('de');
      this.activeLang = 'de';
      localStorage.setItem('lang', 'de');
      return;
    }
    if (l === 'en') {
      this.translate.use('en');
      this.activeLang = 'en';
      localStorage.setItem('lang', 'en');
      return;
    }
    if (l === 'es') {
      this.translate.use('es');
      this.activeLang = 'es';
      localStorage.setItem('lang', 'es');
      return;
    } else {
      this.translate.use('de');
      this.activeLang = 'de';
      localStorage.setItem('lang', 'de');
      return;
    }
  }
}
