import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AccessData } from '../models/access-data.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authService: AuthService,
        private toastr: ToastrService,
        private translate: TranslateService
        // private errorService: ErrorService
    ) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    // tslint:disable-next-line:max-line-length
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        // reset errors
        // this.errorService.setError(new Error());
        const accessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')) : undefined;
        const token = accessData ? accessData.access_token : 'Kein accessData / Access Token im Storage';
        return  next.handle(this.addToken(req, token)).pipe(
            catchError ((error) => {
                const splitted = req.url.split('/');
                const urlPath = splitted[splitted.length - 1];
                if (error instanceof HttpErrorResponse) {
                    switch ((error as HttpErrorResponse).status) {
                        case 400:
                            if (urlPath === 'token') {
                                return throwError(error);
                            } else if (urlPath === 'Cancel') {
                                return throwError(error);
                            } else if (urlPath === 'ResetPassword') {
                                return throwError(error);
                            } else if (urlPath === 'Signup') {
                                return throwError(error);
                            } else {
                                return this.handle400Error();
                            }
                        case 401:
                            console.log('401 error: accessData:', accessData);
                            if (urlPath === 'token') {
                              return throwError(error);
                            } else if (urlPath === 'Cancel') {
                                return throwError(error);
                            } else if (urlPath === 'ResetPassword') {
                                return throwError(error);
                            } else if (urlPath === 'Signup') {
                                return throwError(error);
                            }
                            if (!accessData) {
                                this.logoutUser();
                                return;
                            } else {
                                return this.handle401Error(req, next);
                            }
                        case 403:
                            return this.handle403Error();

                        default:
                          return throwError(error);
                    }
                } else {
                    return throwError(error);
                }
            }) as any
        );

    }

    // Error 400: Refresh Token was invalid, logout and redirect to Login
    handle400Error(): any {
        this.logoutUser();
    }

    // Error 401: Access Token was invalid, try to get new Access Token by Refresh Token
    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        console.log('handle401Error, is Refreshing?', this.isRefreshingToken);
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            // const rDate = new Date(JSON.parse(localStorage.getItem('accessData')).refreshTokenExpireIn);
            // const nDate = new Date();

            // if (nDate < rDate) {
            return this.authService.getRefreshToken().pipe(
                    switchMap((newToken: AccessData) => {
                        if (newToken.access_token) {
                            this.tokenSubject.next(newToken.access_token);

                            return next.handle(this.addToken(req, newToken.access_token));
                        }

                        // If we don't get a new token, we are in trouble so logout.
                        return this.logoutUser();
                    }),
                    catchError((error) => {
                        // If there is an exception calling 'refreshToken', bad news so logout.
                        return this.logoutUser();
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );

            // } else {
            //     // refresh token is expired, logout immediately
            //     return this.logoutUser();
            // }


        } else {
            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.addToken(req, token));
                })
            );
        }
    }

    // Error 403: Access Token was valid, but user has no access to requested resource
    handle403Error(): any {
        alert('Error 403: Access Denied. You do not have permission to access the requested resource.');
    }

    logoutUser() {
        this.authService.logout();
        return throwError([]);
    }
}
