import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { RequestService } from './services/request.service';
import {ToastrModule} from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData, LOCATION_INITIALIZED, DatePipe } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { NavigationComponent } from './navigation/navigation.component';
import { MetalValuesComponent } from './metal-values/metal-values.component';
import { OrdersComponent } from './orders/orders.component';
import { CommunicationComponent } from './communication/communication.component';
import { ProfileComponent } from './profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { LogoutComponent } from './logout/logout.component';
import { MatTabsModule } from '@angular/material/tabs';
import { LocalizedDatePipe } from './localized-date.pipe';
import {
  MatInputModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatDialogModule,
  MatSelectModule,
  MatGridListModule,
  MatRadioModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatTableModule,
  MatToolbarModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatMenuModule,
  MatSortModule,
  DateAdapter
} from '@angular/material';
import { LoginComponent } from './auth/login/login.component';
import { ResetComponent } from './auth/reset/reset.component';
import { AuthComponent } from './auth/auth.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './services/auth.service';
import { NavService } from './services/nav.service';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { StatusComponent } from './status/status.component';
import { StatusDetailsComponent } from './status/status-details/status-details.component';
import { LogsComponent } from './logs/logs.component';
import { LogDetailsComponent } from './logs/log-details/log-details.component';
import { ListErrorsComponent } from './list-errors/list-errors.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { NotAuthenticatedGuard } from './guards/notauthenticated.guard';

registerLocaleData(localeDeCh, 'de');
registerLocaleData(localeFrCh, 'fr');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

// wait with app start until 18n stuff has been loaded (enables translate.instant())
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let langToSet = 'de';
      translate.setDefaultLang(langToSet);
      if (localStorage.getItem('lang')) {
        translate.setDefaultLang(localStorage.getItem('lang'));
        translate.use(localStorage.getItem('lang'));
        langToSet = localStorage.getItem('lang');
      } else {
        if (translate.getBrowserLang() === 'de' || translate.getBrowserLang() === 'fr'
        || translate.getBrowserLang() === 'es' || translate.getBrowserLang() === 'en' ) {
          translate.setDefaultLang(translate.getBrowserLang());
          translate.use(translate.getBrowserLang());
          langToSet = translate.getBrowserLang();
        }
        localStorage.setItem('lang', translate.getBrowserLang());
      }
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    MetalValuesComponent,
    OrdersComponent,
    CommunicationComponent,
    ProfileComponent,
    DashboardComponent,
    OrderDetailsComponent,
    LogoutComponent,
    LoginComponent,
    ResetComponent,
    LocalizedDatePipe,
    AuthComponent,
    ForgotComponent,
    ClientSearchComponent,
    StatusComponent,
    StatusDetailsComponent,
    LogsComponent,
    LogDetailsComponent,
    ListErrorsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    NgxChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    LocalizedDatePipe,
    ApiService,
    RequestService,
    AuthService,
    NavService,
    NavigationComponent,
    AuthenticatedGuard,
    NotAuthenticatedGuard,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'de-CH'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
