import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  auth = {
    email: null,
    password: null,
  };
  loading = false;
  error = null;
  success = false;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {

  }

  onSubmit() {
    this.success = false;
    this.error = null;
    this.loading = true;
    this.authService.forgotPasswordRequest(this.auth.email).subscribe((res) => {
      this.loading = false;
      this.success = true;
    }, (err) => {
      this.success = false;
      this.loading = false;
      this.error = err;
    });
  }

}
