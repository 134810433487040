import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { Profile } from '../models/profile.model';

@Injectable()
export class RequestService {
  orderSearchValue = new BehaviorSubject<string>(null);

  constructor(
    private apiService: ApiService
  ) { }

  getAllMetalsForClient() {
    return this.apiService.get('/api/metals', 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getMasterAllMetalsForClient(clientID) {
    return this.apiService.get('/api/metals?userid=' + clientID, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getAllOrdersForClient() {
    return this.apiService.get('/api/orders', 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getMasterAllOrdersForClient(clientID) {
    return this.apiService.get('/api/orders?userid=' + clientID, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getOrderDetails(id) {
    return this.apiService.get('/api/orders/' + id, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getMasterExportOrdersFileName(clientID) {
    return this.apiService.get('/api/export/orders?userid=' + clientID, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getExportOrdersFileNameForClient() {
    return this.apiService.get('/api/export/orders', 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getMasterOrderDetails(id, clientID) {
    return this.apiService.get('/api/orders/' + id + '?userid=' + clientID, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getAllDocumentsOfClient() {
    return this.apiService.get('/api/customers/downloads', 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getMasterAllDocumentsOfClient(clientID) {
    return this.apiService.get('/api/customers/downloads?userid=' + clientID, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getClientProfile() {
    return this.apiService.get('/api/customers/profile', 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getMasterClientProfile(clientID) {
    return this.apiService.get('/api/customers/profile?userid=' + clientID, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  updateClientProfile(body) {
    return this.apiService.put('/api/customers/profile', body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateMasterClientProfile(body, clientID) {
    return this.apiService.put('/api/customers/profile?userid=' + clientID, body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateClientPassword(body) {
    return this.apiService.put('/api/customers/changepassword', body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateMasterClientPassword(body, clientID) {
    return this.apiService.put('/api/customers/changepassword?userid=' + clientID, body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  searchClient(searchText: string) {
    return this.apiService.get('/api/customers/search?search=' + searchText, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  downloadFile(body) {
    return this.apiService.post('/api/downloadfile', body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  downloadMasterFile(body, clientID) {
    return this.apiService.post('/api/downloadfile?userid=' + clientID, body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getLogOverview() {
    return this.apiService.get('/api/logoverview', 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getLogDetails(id) {
    return this.apiService.get('/api/logs/' + id, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  getLogByDate(first, second) {
    return this.apiService.get('/api/logs?from=' + first + '&to=' + second, 'json', true).pipe(
      map((data) => {
        return data;
      }
      )
    );
  }

  newUsername(body, id) {
    return this.apiService.put('/api/customers/username?userid=' + id, body, 'json', true).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
