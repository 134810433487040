import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {
    constructor(private router: Router, private snackBar: MatSnackBar, private translate: TranslateService) { }
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        const logged = localStorage.getItem('accessData');
        if (!logged) {
          //this.router.navigateByUrl('/dashboard');
          return true;
        }
        this.translate.get('AUTHENTICATED_GUARD_AUTHENTICATED_TOAST').subscribe( res => {
          this.openSnackBar(res);
        });
        this.router.navigateByUrl('/dashboard');
        return false;
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, null, {
            duration: 2000,
        });
    }
}
