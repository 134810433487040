import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { AccessData } from '../models/access-data.model';
import { BehaviorSubject, throwError } from 'rxjs';
import { Profile } from '../models/profile.model';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
/*import { JwtHelperService } from '@auth0/angular-jwt';
import { DecodedJWT } from './models/decoded-jwt.model';*/

@Injectable()
export class AuthService {

  accessData = new BehaviorSubject<AccessData>(new AccessData());
  profile = new BehaviorSubject<Profile>(new Profile());
  clientName = 'frontendApp';
  referenceId = 'Postman'; // Todo use real referenceID (passed from native app)
  userFormBackup: any;
  redirectToBasket = new BehaviorSubject<boolean>(false);
  clientId = 'estoppey';
  //decodedJWT = new BehaviorSubject<DecodedJWT>(null);

  public localUserData: any;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
    this.localUserData = JSON.parse(localStorage.getItem('accessData'));
    if (this.localUserData) {
      this.handleNextAccessData(this.localUserData);
    }
  }

  isAuthenticated() {
    return this.accessData.value.access_token ? true : false;
  }

  logout() {
    localStorage.removeItem('accessData');
    localStorage.removeItem('account');
    localStorage.removeItem('userOrders');
    localStorage.removeItem('hasSeenOnboarding');
    localStorage.removeItem('acceptAGB');
    localStorage.removeItem('acceptPrivacyPolicy');
    localStorage.removeItem('userFormBackup');
    localStorage.removeItem('clientID');
    localStorage.removeItem('lang'),
    localStorage.removeItem('logDetailsSelected');
    localStorage.removeItem('selectedOrder');
    localStorage.removeItem('isMaster');
    localStorage.removeItem('firstDate');
    localStorage.removeItem('secondDate');
    this.accessData.next(new AccessData());
    this.profile.next(new Profile());
    this.router.navigateByUrl('/auth/login');
  }

  createUserAccount(user) {
    return this.apiService.post('/api/Account/Signup', user, 'json', false).pipe(
      map(
        (data: AccessData) => {
          this.handleNextAccessData(data);
          this.getAccount().subscribe(
            () => {
              // done
            }
          );

          return data;
        }
      ),
      catchError(
        (error) => {
          return throwError(error);
        }
      )
    );
  }

  handleNextAccessData(accessData: AccessData) {
    this.accessData.next(accessData);
    localStorage.setItem('accessData', JSON.stringify(accessData));
    //this.decodeJWT(accessData);
  }

  initLocalData() {
    if (localStorage.getItem('accessData')) {
      this.handleNextAccessData(JSON.parse(localStorage.getItem('accessData')));
      this.getAccount().subscribe(
        () => {
          // done
        }
      );
    }
  }

  login(username, password) {
    const formData = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', this.clientId)
      .set('client_secret', environment.client_secret);
    return this.apiService.post('/token', formData, 'form', false).pipe(
      map(
        (data: AccessData) => {
          this.handleNextAccessData(data);
          return data;
        }, err => {
          console.log('Error', err);
        }
      )
    );
  }

  getRefreshToken() {
    // tslint:disable-next-line:max-line-length
    const refresh_token = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData')).refresh_token : 'No refresh_token';
    const formData = new HttpParams()
    .set('grant_type', 'refresh_token')
    .set('client_id', this.clientId)
    .set('client_secret', environment.client_secret)
    .set('refresh_token', refresh_token);
    return this.apiService.post('/token', formData, 'form', false).pipe(
      map(
        (data: AccessData) => {
          this.handleNextAccessData(data);
          return data;
        }
      )
    );
  }

  forgotPasswordRequest(eMail) {
    const user = {
      email: eMail
    };
    return this.apiService.post('/api/customers/forgetpassword', user, 'json', false).pipe(
      map(
        (data) => {

          return data;
        }
      )
    );
  }

  /*forgotPasswordReset(b) {
    return this.apiService.post('/api/Account/ResetPassword', b, 'json', false).pipe(
      map(
        (data) => {

          return data;
        }
      )
    );
  }

  changePassword(password) {
    return this.apiService.patch('/api/Account/Password', password, 'json', true).pipe(
      map(
        (data) => {

          return data;
        }
      )
    );
  }

  deleteAccount() {
    return this.apiService.delete('/api/Account', {}, 'json', true).pipe(
      map(
        (data) => {
          this.logout();

          return data;
        }
      )
    );
  }

  updateAccount(account) {
    return this.apiService.put('/api/Profiles/My', account, 'json', true).pipe(
      map(
        (data) => {
          this.profile.next(data);

          return data;
        }
      )
    );
  }*/

  getAccount() {
    return this.apiService.get('/api/Profiles/My', 'json', true).pipe(
      map(
        (data) => {
          localStorage.setItem('account', JSON.stringify(data));
          this.profile.next(data);

          return data;
        }
      )
    );
  }


  /*decodeJWT(token) {
    const jwt = new JwtHelperService();
    console.log(token);
    const decodedToken = jwt.decodeToken(token.access_token);
    this.decodedJWT.next(decodedToken);
    console.log('decodedToken: ', decodedToken);
  }*/

}
