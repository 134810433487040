import { Component, OnInit } from '@angular/core';
import { RequestService } from '../services/request.service';
import { MatTableDataSource } from '@angular/material/table';
import { Profile } from '../models/profile.model';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit {
  searchText = null;
  results = null;
  dataSource = null;
  navItems: any;

  errors: Errors = new Errors();
  displayedColumns = ['userid', 'first_name', 'last_name', 'company', 'more'];
  constructor(
    private requestService: RequestService,
    private router: Router,
    private navService: NavService,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('status');
   }
   activeNavItem = null;

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.updateNavigation();
    }
  }

  onSubmit() {
    this.requestService.searchClient(this.searchText).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
    }, err => {
      this.errors = err;
      console.log('Error:', err);
    });
  }

  showClient(client: Profile) {
    localStorage.setItem('clientID', client.userid);
    this.router.navigateByUrl('/dashboard');
  }

  updateNavigation() {
    this.navItems = this.navService.navItems;
    this.navItems[5].visible = true;
    this.navItems[6].visible = true;
    this.navService.navItems = this.navItems;
  }
}
