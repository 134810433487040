import { Component, OnInit } from '@angular/core';
import { Metal } from '../models/metal';
import { RequestService } from '../services/request.service';
import { MatTableDataSource } from '@angular/material/table';
import { NgxChartsModule, ChartCommonModule } from '@swimlane/ngx-charts';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../services/nav.service';
import { Profile } from '../models/profile.model';
import { TouchSequence } from 'selenium-webdriver';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-metal-values',
  templateUrl: './metal-values.component.html',
  styleUrls: ['./metal-values.component.scss']
})
export class MetalValuesComponent implements OnInit {

  loading = {
    metals: false
  };

  showPlatine = true;

  creatingChart = false;

  displayedColumns = ['week', 'silver', 'gold', 'palladium', 'platine'];
  dataSource = null;

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = this.translate.instant('METAL_VALUES_CHART_WEEK');
  yAxisLabel = '';
  showYAxisLabel = false;
  timeline = true;

  colorScheme = {
    domain: ['#EBB53E', '#CB4F40', '#479A5F', '#5187ED']
  };

  multi = [];

  errors: Errors = new Errors();

  constructor(
    private requestService: RequestService,
    private translate: TranslateService,
    private navService: NavService,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('metals');
    this.navService.setActiveMaster();
  }

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.getMetals();
      if (localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.metals = false;
          console.log('Error:', err);
        });
    }
      if (!localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.metals = false;
          console.log('Error:', err);
        });
      }
      /*if (localStorage.getItem('clientID')) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        });
      } else {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        });
      }*/
    }
  }

  getMetals() {
    this.errors = new Errors();
    this.loading.metals = true;
    if (localStorage.getItem('clientID')) {
      this.requestService.getMasterAllMetalsForClient(localStorage.getItem('clientID')).subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        let platineSum = 0;
        data.forEach(element => {
          platineSum += Number(element.platine);
        });
        if (platineSum === 0) {
          this.showPlatine = false;
          this.displayedColumns = ['week', 'silver', 'gold', 'palladium'];
        } else {
          this.showPlatine = true;
        }
        this.loading.metals = false;
        this.generateChart(data);
      }, err => {
        this.errors = err;
        this.loading.metals = false;
      });
    } else {
      this.requestService.getAllMetalsForClient().subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        let platineSum = 0;
        data.forEach(element => {
          platineSum += Number(element.platine);
        });
        if (platineSum === 0) {
          this.showPlatine = false;
          this.displayedColumns = ['week', 'silver', 'gold', 'palladium'];
        } else {
          this.showPlatine = true;
        }
        this.loading.metals = false;
        this.generateChart(data);
      }, err => {
        this.errors = err;
        this.loading.metals = false;
        console.log('Error:', err);
      });
    }
  }

  generateChart(data) {
    this.creatingChart = true;
    const temp = [];
    const gold = {
      name: this.translate.instant('METAL_VALUES_CHART_GOLD'),
      series: []
    };
    if (this.showPlatine) {
      const platine = {
        name: this.translate.instant('METAL_VALUES_CHART_PLATINE'),
        series: []
      };
      data.forEach(element => {
        platine.series.push({
          name: element.week,
          value: Number(element.platine)
        });
      });
      temp.push(platine);
    }

    const silver = {
      name: this.translate.instant('METAL_VALUES_CHART_SILVER'),
      series: []
    };
    const palladium = {
      name: this.translate.instant('METAL_VALUES_CHART_PALLADIUM'),
      series: []
    };

    data.forEach(element => {
      gold.series.push({
        name: element.week,
        value: Number(element.gold)
      });
    });

    data.forEach(element => {
      silver.series.push({
        name: element.week,
        value: Number(element.silver)
      });
    });
    data.forEach(element => {
      palladium.series.push({
        name: element.week,
        value: Number(element.palladium)
      });
    });

    temp.push(gold);
    temp.push(silver);
    temp.push(palladium);
    this.multi = temp;
    this.creatingChart = false;
  }
}
