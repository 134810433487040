import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  auth = {
    username: null,
    password: null,
  };
  loading = false;
  error = null;
  debug: boolean;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    /*if (location.hostname === 'localhost') {
      this.auth.username = 'Morpheus';
      this.auth.password = 'Teddy18!';
      this.debug = true;
    }*/
  }

  onSubmit() {
    this.error = null;
    this.loading = true;
    this.authService.login(this.auth.username, this.auth.password).subscribe((res) => {
      setTimeout(() => {
        this.loading = false;
        if (res.scope === 'estoppey_user' || res.scope === 'steiger_user') {
          return this.router.navigateByUrl('/dashboard');
        }
        if (res.scope === 'estoppey_master') {
          localStorage.setItem('isMaster', 'master');
          return this.router.navigateByUrl('/clientsearch');
        } else {
          return this.router.navigateByUrl('/auth/login');
        }
      }, 100);

    }, (err) => {
      this.auth.password = null;
      this.loading = false;
      this.error = err.error.error_description;
      console.log(err);
    });
  }

}
