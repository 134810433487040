export class Profile {
  userid: string;
  ciscompanyuserid: string;
  ciscompanyid: string;
  username: string;
  company: string;
  city: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  usergroups: string;
  scope: string;
  navigations: {
      DASHBOARD: boolean;
      METALS: boolean;
      ORDERS: boolean;
      COMMUNICATION: boolean;
      PROFILE: boolean;
      LOGOUT: boolean;
  };
}
