import { Component, OnInit } from '@angular/core';
import { RequestService } from '../services/request.service';
import { Profile } from '../models/profile.model';
import { ThrowStmt } from '@angular/compiler';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../services/nav.service';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile = {
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    scope: null
  };

  username = {
    newUsername: null
  };

  isMaster = false;

  password = {
    newpassword: null,
    newpasswordsecond: null
  };

  loading = {
    profile: false
  };

  errors: Errors = new Errors();

  constructor(
    private requestService: RequestService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private navService: NavService,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('profile');
   }

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.getUserProfile();
      this.navService.setActiveMaster();
      if (localStorage.getItem('clientID')) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.profile = false;
        });
      } else {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.profile = false;
        });
      }
    }
  }

  getUserProfile() {
    this.loading.profile = true;
    if (localStorage.getItem('clientID')) {
      this.isMaster = true;
      this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
        this.profile.email = data.email;
        this.profile.first_name = data.first_name;
        this.profile.last_name = data.last_name;
        this.profile.phone = data.phone;
        this.profile.scope = data.scope;
        this.username.newUsername = data.username;
        this.loading.profile = false;

      }, err => {
        this.errors = err;
        this.loading.profile = false;
      });
    } else {
      this.isMaster = false;
      this.requestService.getClientProfile().subscribe((data: Profile) => {
        this.profile.email = data.email;
        this.profile.first_name = data.first_name;
        this.profile.last_name = data.last_name;
        this.profile.phone = data.phone;
        this.loading.profile = false;
      }, err => {
        this.errors = err;
        this.loading.profile = false;
      });
    }
  }

  onSubmitProfile() {
    if (localStorage.getItem('clientID')) {
      this.requestService.updateMasterClientProfile(this.profile, localStorage.getItem('clientID')).subscribe(res => {
        this.snackBar.open(this.translate.instant('PROFILE_UPDATE_SUCCESSFULL'), null, { duration: 2000 });
      },
      err => {
        console.log(err);
        this.snackBar.open(err, null, { duration: 2000 });
      });
    } else {
      this.requestService.updateClientProfile(this.profile).subscribe(res => {
        this.snackBar.open(this.translate.instant('PROFILE_UPDATE_SUCCESSFULL'), null, { duration: 2000 });
      },
      err => {
        console.log(err);
        this.snackBar.open(err, null, { duration: 2000 });
      });
    }
  }

  onSubmitPassword() {
    if (localStorage.getItem('clientID')) {
      this.requestService.updateMasterClientPassword(this.password, localStorage.getItem('clientID')).subscribe(res => {
        this.snackBar.open(this.translate.instant('PROFILE_PASSWORD_CHANGE_SUCCESSFULL'), null, { duration: 2000 });
      },
      err => {
        console.log(err);
        this.snackBar.open(err, null, { duration: 2000 });
      });
    } else {
      this.requestService.updateClientPassword(this.password).subscribe(res => {
        this.snackBar.open(this.translate.instant('PROFILE_PASSWORD_CHANGE_SUCCESSFULL'), null, { duration: 2000 });
      },
      err => {
        console.log(err);
        this.snackBar.open(err, null, { duration: 2000 });
      });
    }
  }

  onSubmitUsername() {
    if (!localStorage.getItem('isMaster')) { return; }
    this.requestService.newUsername(this.username, localStorage.getItem('clientID')).subscribe(res => {
      this.snackBar.open(this.translate.instant('PROFILE_USERNAME_CHANGE_SUCCESSFULL'), null, { duration: 2000 });
    },
    err => {
      console.log(err);
      this.snackBar.open(err, null, { duration: 2000 });
    });
  }
}
