import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MetalValuesComponent } from './metal-values/metal-values.component';
import { OrdersComponent } from './orders/orders.component';
import { CommunicationComponent } from './communication/communication.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './auth/login/login.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthComponent } from './auth/auth.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { StatusComponent } from './status/status.component';
import { StatusDetailsComponent } from './status/status-details/status-details.component';
import { LogsComponent } from './logs/logs.component';
import { LogDetailsComponent } from './logs/log-details/log-details.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { NotAuthenticatedGuard } from './guards/notauthenticated.guard';



const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'auth', component: AuthComponent, canActivate: [NotAuthenticatedGuard] , children: [
    { path: 'login', component: LoginComponent },
    { path: 'forgot', component: ForgotComponent },
  ]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticatedGuard] },
  { path: 'metalvalues', component: MetalValuesComponent, canActivate: [AuthenticatedGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthenticatedGuard] },
  { path: 'orders/order-details/:id', component: OrderDetailsComponent, canActivate: [AuthenticatedGuard] },
  { path: 'communication', component: CommunicationComponent, canActivate: [AuthenticatedGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthenticatedGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'clientsearch', component: ClientSearchComponent, canActivate: [AuthenticatedGuard] },
  { path: 'status', component: StatusComponent, canActivate: [AuthenticatedGuard] },
  { path: 'status/status-details/:id', component: StatusDetailsComponent, canActivate: [AuthenticatedGuard] },
  { path: 'logs', component: LogsComponent, canActivate: [AuthenticatedGuard] },
  { path: 'logs/log-details/:id', component: LogDetailsComponent, canActivate: [AuthenticatedGuard] }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
