import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '../services/request.service';
import { NavService } from '../services/nav.service';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  constructor(
    private router: Router,
    private requestService: RequestService,
    private navService: NavService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('status');
   }

   isMaster = null;
   steigerLogs = null;
   estoppeyLogs = null;
   loading = {
     logs: false
   };

   firstDate = null;
   secondDate = null;

   displayedColumns = ['state', 'scopetxt', 'logdate', 'more'];
   dataSourceEstoppeyDate = null;
   dataSourceSteigerDate = null;

   errors: Errors = new Errors();

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.navService.setActiveMaster();
      if (localStorage.getItem('isMaster')) {
        this.isMaster = true;
      } else {
        this.isMaster = false;
      }
      if (localStorage.getItem('firstDate') && localStorage.getItem('secondDate')) {
        this.firstDate = new Date(localStorage.getItem('firstDate'));
        this.secondDate = new Date(localStorage.getItem('secondDate'));
        this.getLogs();
      }
    }
  }

  getLogs() {
    this.loading.logs = true;
    localStorage.setItem('firstDate', this.firstDate);
    localStorage.setItem('secondDate', this.secondDate);
    const first = this.datePipe.transform(this.firstDate, 'yyyy-MM-dd');
    const second = this.datePipe.transform(this.secondDate, 'yyyy-MM-dd');
    const steigerLogs = [];
    const estoppeyLogs = [];
    this.requestService.getLogByDate(first, second).subscribe((data) => {
      data.forEach(element => {
        if (element.ciscompanyid.toLowerCase() === 'steiger') {
          steigerLogs.push(element);
        }
        if (element.ciscompanyid.toLowerCase() === 'estoppey') {
          estoppeyLogs.push(element);
        }
      });
      this.dataSourceSteigerDate = new MatTableDataSource(steigerLogs);
      this.dataSourceEstoppeyDate = new MatTableDataSource(estoppeyLogs);
    }, err => {
      this.errors = err;
      this.loading.logs = false;
      console.log('Error:', err);
    });
    this.loading.logs = false;
  }

  showLogDetails(logid) {
    localStorage.setItem('logDetailsSelected', logid);
    this.router.navigate(['logs/log-details/', logid]);
  }

  resetLogs() {
    this.dataSourceEstoppeyDate = null;
    this.dataSourceSteigerDate = null;
    this.firstDate = null;
    this.secondDate = null;
    localStorage.removeItem('firstDate');
    localStorage.removeItem('secondDate');
  }

}
