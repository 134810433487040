import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '../services/request.service';
import { NavService } from '../services/nav.service';
import { Profile } from '../models/profile.model';
import { LogOverview, CompanyLogs } from '../models/log-overview.model';
import { MatTableDataSource } from '@angular/material';
import { DatePipe } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  constructor(
    private router: Router,
    private requestService: RequestService,
    private navService: NavService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('status');
  }

  displayedColumns = ['state', 'scopetxt', 'lastlogdate', 'more'];
  dataSourceSteiger = null;
  dataSourceEstoppey = null;
  dataSourceEstoppeyDate = null;
  dataSourceSteigerDate = null;

  isMaster = null;
  steigerLogs = null;
  estoppeyLogs = null;
  loading = {
    logs: false
  };

  errors: Errors = new Errors();

  firstDate = null;
  secondDate = null;

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
    this.navService.setActiveMaster();
    this.loading.logs = true;
    if (localStorage.getItem('isMaster')) {
      this.isMaster = true;
    } else {
      this.isMaster = false;
    }
    if (localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.logs = false;
        });
    }
    if (!localStorage.getItem('clientID') && !this.navService.activeNavItem) {
      this.requestService.getClientProfile().subscribe((data: Profile) => {
        this.navService.updateNavigation(data.navigations);
      }, err => {
        this.errors = err;
        this.loading.logs = false;
      });
    }
    this.getInitialLogs();
  }
  }

  showLogDetails(logid) {
    localStorage.setItem('logDetailsSelected', logid);
    this.router.navigate(['status/status-details/', logid]);
  }

  getInitialLogs() {
    this.errors = new Errors();
    if (this.isMaster) {
      this.requestService.getLogOverview().subscribe((data) => {
        this.dataSourceEstoppey = new MatTableDataSource(data.estoppey.logoverviewitems);
        this.dataSourceSteiger = new MatTableDataSource(data.steiger.logoverviewitems);
        this.steigerLogs = data.steiger;
        this.estoppeyLogs = data.estoppey;

        this.loading.logs = false;
      }, err => {
        this.errors = err;
        this.loading.logs = false;
      });
    } else {
      this.loading.logs = false;
      return;
    }
  }
}
