import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { NavService } from 'src/app/services/nav.service';
import { LogDetails } from 'src/app/models/log-overview.model';
import { AuthService } from 'src/app/services/auth.service';
import { Errors } from 'src/app/models/errors.model';

@Component({
  selector: 'app-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.scss']
})
export class LogDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private requestService: RequestService,
    private navService: NavService,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('logs');
  }
  isMaster = null;
  loading = {
    details: false
  };

  dataSource = null;
  errorLog = null;

  errors: Errors = new Errors();

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.navService.setActiveMaster();
      this.loading.details = true;
      if (localStorage.getItem('isMaster')) {
        this.isMaster = true;
      } else {
        this.isMaster = false;
      }
      if (this.isMaster) {
        this.requestService.getLogDetails(localStorage.getItem('logDetailsSelected')).subscribe((data: LogDetails) => {
          this.errorLog = data.errorlong;
          this.loading.details = false;
        }, err => {
          this.errors = err;
          this.loading.details = false;
          console.log('Error:', err);
        });
      } else {
        this.loading.details = false;
        return;
      }
    }
  }
}
