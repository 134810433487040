import { Component, OnInit } from '@angular/core';
import { Document } from '../models/document';
import { RequestService } from '../services/request.service';
import { NavService } from '../services/nav.service';
import { Profile } from '../models/profile.model';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

  documents: Document[] = null;

  loading = {
    documents: false
  };

  displayedColumns = ['filename', 'download'];
  dataSource = null;

  errors: Errors = new Errors();

  constructor(
    private requestService: RequestService,
    private navService: NavService,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('communication');
  }

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.getDocuments();
      this.navService.setActiveMaster();
      if (localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.documents = false;
          console.log('Error:', err);
        });
      }
      if (!localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.documents = false;
          console.log('Error:', err);
        });
      }
      /*if (localStorage.getItem('clientID')) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        });
      } else {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        });
      }*/
    }
  }

  getDocuments() {
    this.loading.documents = true;
    if (localStorage.getItem('clientID')) {
      this.requestService.getMasterAllDocumentsOfClient(localStorage.getItem('clientID')).subscribe((data: Document) => {
        this.dataSource = data;
        this.loading.documents = false;
        this.dataSource = data;
      }, err => {
        this.errors = err;
        this.loading.documents = false;
        console.log('Error:', err);
      });
    } else {
      this.requestService.getAllDocumentsOfClient().subscribe((data: Document) => {
        this.dataSource = data;
        this.loading.documents = false;
        this.dataSource = data;
      }, err => {
        this.errors = err;
        this.loading.documents = false;
        console.log('Error:', err);
      });
    }
  }

  download(selectedPath) {
    const file = {
      path: selectedPath.path
    };
    if (localStorage.getItem('clientID')) {
      this.requestService.downloadMasterFile(file, localStorage.getItem('clientID')).subscribe(res => {
        const linkSource = 'data:application/pdf;base64,' + res.file;
        const downloadLink = document.createElement('a');
        const fileName = new Date().toISOString() + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, err => {
        console.log(err);
      });
    } else {
      this.requestService.downloadFile(file).subscribe(res => {
        const linkSource = 'data:application/pdf;base64,' + res.file;
        const downloadLink = document.createElement('a');
        const fileName = new Date().toISOString() + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, err => {
        console.log(err);
      });
    }
  }
}
