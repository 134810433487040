import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  auth = {
    username: null,
    password: null,
  };
  loading = false;
  error = null;
  success = false;
  userId;
  code;
  routeSub: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.userId = params.userId;
      this.code = params.code;
    });
  }

  /*onSubmit() {
    if (!this.code || !this.userId) {
      // tslint:disable-next-line:max-line-length
      // this.openConfirmationDialog('Der Passwort-Reset Email-Link konnte nicht verarbeitet werden. Bitte kontaktieren Sie den Support!', 'ok');
      return;
    }
    this.success = false;
    this.error = null;
    this.loading = true;
    const b = {
      userId: this.userId,
      code: this.code,
      password: this.auth.password
    };
    this.authService.forgotPasswordReset(b).subscribe((res) => {
      this.loading = false;
      this.success = true;
      // this.openConfirmationDialog(this.translate.instant('AUTH_RESET_SUCCESS'), 'ok');
      setTimeout(() => {
        this.router.navigateByUrl('/profile/login');
      }, 200);
    }, (err) => {
      this.success = false;
      this.loading = false;
      this.error = err;
      console.log(err);
      // this.openConfirmationDialog(this.translate.instant('AUTH_RESET_ERROR') + err.error.ReferenceErrorId, 'ok');
    });
  }*/

  /*openConfirmationDialog(message, actions) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        actions
      }
    });

    dialogRef.afterClosed().subscribe(
      (result) => {
        // done
      }
    );
  }*/
}
