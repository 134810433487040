import { Component, Input } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceInDown, bounceInUp, slideInDown } from 'ng-animate';

import { Errors } from '../models/errors.model';

@Component({
  selector: 'list-errors',
  templateUrl: './list-errors.component.html',
  styleUrls: ['./list-errors.component.scss'],
  animations: [
    trigger('errorEntryAnimation', [transition('* => *', useAnimation(bounce, { params: { timing: 0.5, delay: 0 }}))]),
]
})
export class ListErrorsComponent {
  errorAnimation = 'errorEntryAnimation';
  formattedErrors: any;
  closed = false;

  @Input()
  set errors(errorList: Errors) {
    this.closed = false;
    this.formattedErrors = errorList;
  }

  get errorList() {
    return this.formattedErrors; }

  onCloseError() {
    this.closed = true;
  }
}
