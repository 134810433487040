import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavLink } from '../models/nav-link.model';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../services/nav.service';
import { AuthService } from '../services/auth.service';

import { RequestService } from '../services/request.service';
import { Profile } from '../models/profile.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AccessData } from '../models/access-data.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  activeNav: NavLink;
  navItems: any;
  isMaster = false;
  accessData: AccessData;
  accessDataSubscription: any;
  activeNavItem: any;
  activeNavItemSubscription: any;


  constructor(
    private router: Router,
    private translate: TranslateService,
    private requestService: RequestService,
    private authService: AuthService,
    private navService: NavService,
  ) {
    this.navItems = navService.navItems;
  }

  ngOnInit() {
    this.navService.activeMaster.subscribe((master) => {
      this.isMaster = master;
    });

    this.activeNavItemSubscription = this.navService.activeNavItem.subscribe(
      (navItem) => {
        if (navItem) {
          this.activeNavItem = navItem;
        }
      }
    );
    this.accessDataSubscription = this.authService.accessData.subscribe(
      (data) => {
        if (data) {
          this.accessData = data;
        }
      }
    );
  }

  ngOnDestroy() {
    this.accessDataSubscription.unsubscribe();
  }

  visibleNavLinks() {
    return this.navItems.filter((n) => n.visible);
  }

  returnToClientSearch() {
    localStorage.removeItem('clientID');
    this.isMaster = false;
    this.router.navigate(['/clientsearch']);
  }
}
