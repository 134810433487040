import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiService {
  public bearerToken: string;
  public currentUser: any;
  loading = false;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) { }

  setHeaders(headerType, authenticate) {
    const headersConf = {};
    headersConf['Accept-Language'] = this.translateService.currentLang;
    if (headerType === 'json') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'json-x') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'form') {
      headersConf['Content-Type'] = 'application/x-www-form-urlencoded';
    } else if (headerType === 'multipart') {
      headersConf['Content-Type'] = 'multipart/form-data';
    }
    // Authorization
    if (authenticate) {
      if (localStorage.getItem('accessData')) {
        const accessData = JSON.parse(localStorage.getItem('accessData'));
        // tslint:disable-next-line:no-string-literal
        headersConf['Authorization'] = 'bearer ' + accessData.access_token;
      }
    }
    return new HttpHeaders(headersConf);
  }

  get(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.api_url + path, { headers });
  }

  getBlob(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.api_url + path, {
      headers, observe: 'response',
      responseType: 'blob' as 'json' });
  }

  patch(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.patch(environment.api_url + path, body, { headers });
  }

  post(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { headers });
  }

  uploadWithProgress(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { reportProgress: true, observe: 'events' });
  }

  put(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.put(environment.api_url + path, body, { headers });
  }

  delete(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.delete(environment.api_url + path, { headers });
  }

}
