import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

const { log } = console;

@Injectable()
export class NavService {

  navItems = [
    { path: '/dashboard', name: 'dashboard', i18n: 'NAV_DASHBOARD', visible: true },
    { path: '/metalvalues', name: 'metals', i18n: 'NAV_METAL_VALUES', visible: true },
    { path: '/orders', name: 'orders', i18n: 'NAV_ORDERS', visible: true },
    { path: '/communication', name: 'communication', i18n: 'NAV_COMMUNICATION', visible: true },
    { path: '/profile', name: 'profile', i18n: 'NAV_PROFILE', visible: true },
    { path: '/status', name: 'status', i18n: 'NAV_STATUS', visible: false },
    { path: '/logs', name: 'logs', i18n: 'NAV_LOGS', visible: false},
    { path: '/logout', name: 'logout', i18n: 'NAV_LOGOUT', visible: true },
  ];
  activeNavItem = new BehaviorSubject<any>(null);
  activeMaster = new BehaviorSubject<any>(null);

  constructor(
  ) { }


  setActiveNavItem(name) {
    if (!name) {
      this.activeNavItem.next(null);
    } else {
      this.navItems.forEach(
        (n) => {
          if (n.name === name) {
            this.activeNavItem.next(n);
          }
        }
      );
    }
  }

  setActiveMaster() {
    if (localStorage.getItem('isMaster')) {
      this.activeMaster.next(true);
      this.navItems[5].visible = true;
      this.navItems[6].visible = true;
    } else {
      this.activeMaster.next(false);
      this.navItems[5].visible = false;
      this.navItems[6].visible = false;
    }
  }

  updateNavigation(profileNavItems) {
    if (profileNavItems.DASHBOARD) {
      this.navItems[0].visible = true;
    } else {
      this.navItems[0].visible = false;
    }
    if (profileNavItems.METALS) {
      this.navItems[1].visible = true;
    } else {
      this.navItems[1].visible = false;
    }
    if (profileNavItems.ORDERS) {
      this.navItems[2].visible = true;
    } else {
      this.navItems[2].visible = false;
    }
    if (profileNavItems.COMMUNICATION) {
      this.navItems[3].visible = true;
    } else {
      this.navItems[3].visible = false;
    }
    if (profileNavItems.PROFILE) {
      this.navItems[4].visible = true;
    } else {
      this.navItems[4].visible = false;
    }
    this.setActiveMaster();
  }
}
