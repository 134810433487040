import { Component, OnInit } from '@angular/core';
import { RequestService } from '../services/request.service';
import { Profile } from '../models/profile.model';
import { NavService } from '../services/nav.service';
import { NavigationComponent } from '../navigation/navigation.component';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  profile = {
    company: null,
    mail: null,
    navigations: null
  };

  navItems: any;
  errors: Errors = new Errors();

  constructor(
    private requestService: RequestService,
    private navService: NavService,
    private navComponent: NavigationComponent,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('dashboard');
  }

  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.getProfile();
    }
  }

  getProfile() {
    this.errors = new Errors();
    if (localStorage.getItem('clientID')) {
      this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
        this.profile.company = data.company;
        this.profile.mail = data.email;
        this.profile.navigations = data.navigations;
        this.updateNavigation();
      }, err => {
        this.errors = err;
        console.log('Error:', err);
      });
    } else {
      this.requestService.getClientProfile().subscribe((data: Profile) => {
        this.profile.company = data.company;
        this.profile.mail = data.email;
        this.profile.navigations = data.navigations;
        this.updateNavigation();
      }, err => {
        this.errors = err;
        console.log('Error:', err);
      });
    }
  }
  updateNavigation() {
    this.navService.updateNavigation(this.profile.navigations);
  }
}
