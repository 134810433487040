import { Component, OnInit, ViewChild } from '@angular/core';
import { Order } from '../models/order.model';
import { MatSort } from '@angular/material/sort';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { RequestService } from '../services/request.service';
import { MatTableDataSource } from '@angular/material/table';
import { NavService } from '../services/nav.service';
import { Profile } from '../models/profile.model';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AuthService } from '../services/auth.service';
import { Errors } from '../models/errors.model';
import { ThrowStmt } from '@angular/compiler';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  order: Order[] = [];

  textSearch = null;

  loading = {
    orders: false
  };

  errors: Errors = new Errors();

  displayedColumns = ['orderreferenceid', 'entrydate', 'deliverydate',
    'secondarydeliverydate', 'deliverystate', 'productname', 'suppliername',
    'deliveryid', 'ciscompanyorderid', 'more'];
  exportCols = ['orderreferenceid', 'entrydate', 'deliverydate',
    'secondarydeliverydate', 'deliverystate', 'productname', 'suppliername', 'deliveryid', 'ciscompanyorderid'];
  displayedCols = ['Order reference ID', 'Entry date', 'Delivery Date',
    'Secondary delivery date', 'Delivery state', 'Product Name', 'Supplier name', 'Delivery ID', 'FA'];
  dataSource: any;
  exportData: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private navService: NavService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {
    navService.setActiveNavItem('orders');
    this.navService.setActiveMaster();
  }

  ngOnInit() {
    this.errors = new Errors();
    if (!this.authService.isAuthenticated()) {
      this.authService.logout();
    } else {
      this.getOrders();
      if (localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.orders = false;
        });
      }
      if (!localStorage.getItem('clientID') && !this.navService.activeNavItem) {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        }, err => {
          this.errors = err;
          this.loading.orders = false;
        });
      }
      /*if (localStorage.getItem('clientID')) {
        this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        });
      } else {
        this.requestService.getClientProfile().subscribe((data: Profile) => {
          this.navService.updateNavigation(data.navigations);
        });
      }*/
    }

  }

  getOrders() {
    this.loading.orders = true;
    if (localStorage.getItem('clientID')) {
      this.requestService.getMasterAllOrdersForClient(localStorage.getItem('clientID')).subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.exportData = data;
        for (const o of this.exportData) {
          if (o.entrydate === '0000-00-00 00:00:00') {
            o.entrydate = '';
          } else {
            o.entrydate = this.datePipe.transform(o.entrydate, 'dd.MM.yyyy');
          }
          if (o.deliverydate === '0000-00-00 00:00:00') {
            o.deliverydate = '';
          } else {
            o.deliverydate = this.datePipe.transform(o.deliverydate, 'dd.MM.yyyy');
          }
          if (o.secondarydeliverydate === '0000-00-00 00:00:00') {
            o.secondarydeliverydate = '';
          } else {
            o.secondarydeliverydate = this.datePipe.transform(o.secondarydeliverydate, 'dd.MM.yyyy');
          }
        }
        if (this.requestService.orderSearchValue.value) {
          this.textSearch = this.requestService.orderSearchValue.value;
          this.applyFilter(this.requestService.orderSearchValue.value);
        }
        this.loading.orders = false;
        this.dataSource.sort = this.sort;
      }, err => {
        this.errors = err;
        this.loading.orders = false;
      });
    } else {
      this.requestService.getAllOrdersForClient().subscribe((data) => {
        this.dataSource = new MatTableDataSource(data);
        this.exportData = data;
        for (const o of this.exportData) {
          if (o.entrydate === '0000-00-00 00:00:00') {
            o.entrydate = '';
          } else {
            o.entrydate = this.datePipe.transform(o.entrydate, 'dd.MM.yyyy');
          }
          if (o.deliverydate === '0000-00-00 00:00:00') {
            o.deliverydate = '';
          } else {
            o.deliverydate = this.datePipe.transform(o.deliverydate, 'dd.MM.yyyy');
          }
          if (o.secondarydeliverydate === '0000-00-00 00:00:00') {
            o.secondarydeliverydate = '';
          } else {
            o.secondarydeliverydate = this.datePipe.transform(o.secondarydeliverydate, 'dd.MM.yyyy');
          }
        }
        if (this.requestService.orderSearchValue.value) {
          this.textSearch = this.requestService.orderSearchValue.value;
          this.applyFilter(this.requestService.orderSearchValue.value);
        }
        this.loading.orders = false;
        this.dataSource.sort = this.sort;
      }, err => {
        this.errors = err;
        this.loading.orders = false;
      });
    }
  }

  showOrderDetails(order: Order) {
    localStorage.setItem('selectedOrder', order.orderid.toString());
    this.router.navigate(['orders/order-details/', order.orderid]);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.requestService.orderSearchValue.next(filterValue.trim().toLowerCase());
  }

  //Outdated
  downloadFileCSV() {
    const csvData = this.convertToCSV(this.exportData, this.exportCols);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', new Date().toISOString() + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  generateFileCSV() {
    let clientId = localStorage.getItem('clientID');
    if (clientId) {
      this.requestService.getMasterExportOrdersFileName(clientId).subscribe(response => {
        if (response && response.filename) {
          window.open(environment.api_url + "/export/" + response.filename, '_blank');
        }
      })
    }
    else {
      this.requestService.getExportOrdersFileNameForClient().subscribe(response => {
        if (response && response.filename) {
          window.open(environment.api_url + "/export/" + response.filename, '_blank');
        }
      })
    }
  }


  convertToCSV(objArray, headerList) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    /*for (const o of array) {
      if (o.entrydate === '0000-00-00 00:00:00') {
        o.entrydate = '';
      } else {
        o.entrydate = o.entrydate.toString().split('00:00:00')[0];
      }
      if (o.deliverydate === '0000-00-00 00:00:00') {
        o.deliverydate = '';
      } else {
        o.deliverydate = o.deliverydate.toString().split('00:00:00')[0];
      }
      if (o.secondarydeliverydate === '0000-00-00 00:00:00') {
        o.secondarydeliverydate = '';
      } else {
        o.secondarydeliverydate = o.secondarydeliverydate.toString().split('00:00:00')[0];
      }
    }*/
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line;
      for (let index in headerList) {
        let head = headerList[index];
        if (!line) {
          line = array[i][head];
        } else {
          if (array[i][head] === '0000-00-00 00:00:00') {
            line += ';' + '';
          } else {
            line += ';' + array[i][head];
          }
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  downloadPdf() {
    const array = typeof this.exportData !== 'object' ? JSON.parse(this.exportData) : this.exportData;
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
    });
    const rows = [];

    for (let i = 0; i < array.length; i++) {
      const line = [];
      for (let j = 0; j < this.exportCols.length; j++) {
        if (j === 1 || j === 2 || j === 3) {
          if (array[i][this.exportCols[j]] === '0000-00-00 00:00:00') {
            line.push('');
          } else {
            const temp = new Date(array[i][this.exportCols[j]]);
            line.push(array[i][this.exportCols[j]]);
          }
        } else {
          line.push(array[i][this.exportCols[j]]);
        }
      }
      rows.push(line);
    }
    doc.autoTable(this.displayedCols, rows);
    doc.save('Download' + new Date().toISOString() + '.pdf');
    JSON.stringify(this.exportData);
  }
}
