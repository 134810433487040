import { Component, OnInit, Inject } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { MatTableDataSource } from '@angular/material';
import { OrderDetails } from 'src/app/models/order.model';
import { NavService } from 'src/app/services/nav.service';
import { Profile } from 'src/app/models/profile.model';
import { Errors } from 'src/app/models/errors.model';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  selectedItem = null;

  loading = {
    details: false
  };

  dataSourceClient = {
    name: null,
    city: null
  };
  dataSource: OrderDetails;
  displayedClientColumns = ['description', 'suppliercity'];
  filePath = null;

  errors: Errors = new Errors();

  constructor(
    private requestService: RequestService,
    private navService: NavService,
  ) {
    navService.setActiveNavItem('orders');
   }

  ngOnInit() {
    this.errors = new Errors();
    this.selectedItem = localStorage.getItem('selectedOrder');
    this.loading.details = true;
    this.navService.setActiveMaster();
    if (localStorage.getItem('clientID')) {
      this.requestService.getMasterOrderDetails(this.selectedItem, localStorage.getItem('clientID')).subscribe((data: OrderDetails) => {
        this.filePath = data.downloads[0];
        this.dataSource = data;
        this.loading.details = false;
      }, err => {
        this.errors = err;
        this.loading.details = false;
      });
    } else {
      this.requestService.getOrderDetails(this.selectedItem).subscribe((data: OrderDetails) => {
        this.filePath = data.downloads[0];
        this.dataSource = data;
        this.loading.details = false;
      }, err => {
        this.errors = err;
        this.loading.details = false;
      });
    }
    if (localStorage.getItem('clientID') && !this.navService.activeNavItem) {
      this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
        this.navService.updateNavigation(data.navigations);
      }, err => {
        this.errors = err;
        this.loading.details = false;
      });
  }
    if (!localStorage.getItem('clientID') && !this.navService.activeNavItem) {
      this.requestService.getClientProfile().subscribe((data: Profile) => {
        this.navService.updateNavigation(data.navigations);
      }, err => {
        this.errors = err;
        this.loading.details = false;
      });
    }
    /*if (localStorage.getItem('clientID')) {
      this.requestService.getMasterClientProfile(localStorage.getItem('clientID')).subscribe((data: Profile) => {
        this.navService.updateNavigation(data.navigations);
      });
    } else {
      this.requestService.getClientProfile().subscribe((data: Profile) => {
        this.navService.updateNavigation(data.navigations);
      });
    }*/
  }

  downloadFile(selectedPath) {
    const file = {
      path: selectedPath
    };
    if (localStorage.getItem('clientID')) {
      this.requestService.downloadMasterFile(file, localStorage.getItem('clientID')).subscribe(res => {
        const linkSource = 'data:application/pdf;base64,' + res.file;
        const downloadLink = document.createElement('a');
        const fileName = new Date().toISOString() + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, err => {
        console.log(err);
      });
    } else {
      this.requestService.downloadFile(file).subscribe(res => {
        const linkSource = 'data:application/pdf;base64,' + res.file;
        const downloadLink = document.createElement('a');
        const fileName = new Date().toISOString() + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, err => {
        console.log(err);
      });
    }
  }
}
